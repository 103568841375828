import { HashLink } from "react-router-hash-link";
import { FaTelegram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import Logo from "../../assets/png/logo.png";
import { PryButton } from "../Buttons/Buttons";
import { ReactComponent as Vector } from "../../assets/svg/vector.svg";
import "./navbar.scss";

interface NavbarProps {
  showMobileMenu: boolean;
  setShowMobileMenu: (showMobileMenu: boolean) => void;
}

const Navbar = ({ showMobileMenu, setShowMobileMenu }: NavbarProps) => {
  const sections = [
    {
      name: "About",
      link: "/#about",
    },
    {
      name: "How to buy",
      link: "/#how-to-buy",
    },
    {
      name: "Tokenomics",
      link: "/#tokenomics",
    },
    {
      name: "FAQs",
      link: "/#faqs",
    },
  ];

  return (
    <section className="nav-container">
      <div className="wrapper desktop_max_width">
        <div className="click">
          <img height={70} className="logo" src={Logo} alt="Logo" />
        </div>

        <ul className="nav-links">
          {sections.map((section, index) => (
            <li key={section.name}>
              <HashLink smooth to={section.link}>
                {section.name} <Vector />
              </HashLink>
            </li>
          ))}
        </ul>

        <div className="nav-btns">
          <PryButton click={() => {}}>Buy $DCAT</PryButton>
        </div>

        <div
          className="hamburger-menu"
          onClick={() => setShowMobileMenu(!showMobileMenu)}
          onKeyDown={() => setShowMobileMenu(!showMobileMenu)}
        >
          <div className={`hamburger ${showMobileMenu ? "open" : ""}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div className={`mobile-menu ${showMobileMenu ? "" : "off"}`}>
          <div
            className={showMobileMenu ? "wrapper-mobile on" : "wrapper-mobile"}
          >
            <ul className="nav-links-mobile">
              {sections.map((section, index) => (
                <li
                  onClick={() => setShowMobileMenu(!showMobileMenu)}
                  onKeyDown={() => setShowMobileMenu(!showMobileMenu)}
                  key={section.name}
                >
                  <HashLink smooth to={section.link}>
                    {section.name} <Vector />
                  </HashLink>
                </li>
              ))}

              <li
                onClick={() => setShowMobileMenu(!showMobileMenu)}
                onKeyDown={() => setShowMobileMenu(!showMobileMenu)}
              >
                <div className="nav-socials mobile">
                  <div className="click">
                    <a
                      href="https://twitter.com/dogacat_"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaXTwitter color="#9b68dc" size={24} />
                    </a>
                  </div>

                  <div className="click">
                    <a
                      href="https://t.me/dogacat_army/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaTelegram color="#9b68dc" size={24} />
                    </a>
                  </div>
                </div>
              </li>

              <li>
                <PryButton click={() => setShowMobileMenu(!showMobileMenu)}>
                  Buy $DCAT
                </PryButton>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Navbar;
