import React from "react";
import { RingProgress, Group, Text, Stack } from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import BannerImage from "../../assets/png/banner_image.png";
import { PryButton, SecButton } from "../../components/Buttons/Buttons";
import { ReactComponent as Vector1 } from "../../assets/svg/banner-vector-one.svg";
import Faqs from "../../components/Faqs/Faqs";
import Carousel1 from "../../assets/png/carousel-1.jpeg";
import Carousel2 from "../../assets/png/carousel-2.jpeg";
import Carousel3 from "../../assets/png/carousel-3.jpeg";
import Carousel4 from "../../assets/png/carousel-4.jpeg";
import Carousel5 from "../../assets/png/carousel-5.jpeg";
import Carousel6 from "../../assets/png/carousel-6.jpeg";
import Carousel7 from "../../assets/png/carousel-7.jpeg";
import Carousel8 from "../../assets/png/carousel-8.jpeg";
import Carousel9 from "../../assets/png/carousel-9.jpeg";
import Carousel10 from "../../assets/png/carousel-10.jpeg";
import "./landing.scss";

const Landing = () => {
  const sections = [
    { value: 97, color: "cyan", tooltip: "Liquidity" },
    { value: 3, color: "grape", tooltip: "Team" },
    // { value: 5, color: "pink", tooltip: "Airdrop" },
    // { value: 5, color: "violet", tooltip: "Team" },
  ];

  const carouselImages = [
    Carousel1,
    Carousel2,
    Carousel3,
    Carousel4,
    Carousel5,
    Carousel6,
    Carousel7,
    Carousel8,
    Carousel9,
    Carousel10,
    Carousel4,
    Carousel7,
  ];

  return (
    <div className="landing">
      <Vector1 className="floating_vector one" />

      <section className="banner desktop_max_width">
        <div
          className="banner_text"
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
          data-aos-duration="1000"
        >
          <h1 className="banner_text_title">
            Welcome to <span className="text_dg">"Dogs</span> <span>&</span>{" "}
            <span className="text_ct">Cats"</span> Memeland{" "}
            <span className="lg-only">
              where laughter knows no species boundaries
            </span>
            !
          </h1>

          <p className="banner_text_desc">
            This project is a whimsical exploration of the hilarious antics,
            adorable moments, and undeniable charm of our furry friends – dogs
            and cats.{" "}
            <span className="lg-only">
              Through a collection of cleverly crafted memes, we aim to capture
              the essence of the age-old debate: are you Team Dog or Team Cat?
            </span>
          </p>

          <div className="banner_text_buttons">
            <PryButton click={() => {}}>Buy $DCAT</PryButton>

            <a
              href="https://t.me/dogacat_army"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SecButton click={() => {}}>Join Community</SecButton>
            </a>
          </div>
        </div>

        <div className="banner_image">
          <img
            data-aos="zoom-out-up"
            data-aos-duration="1000"
            src={BannerImage}
            alt="dog and cat"
          />
        </div>
      </section>

      <section className="action_text">
        <div
          className="desktop_max_width"
          data-aos="flip-up"
          data-aos-duration="1000"
        >
          Support <span>$DOGACAT</span>. Join the community.
        </div>
      </section>

      <section className="section about darker" id="about">
        <div className="desktop_max_width">
          <div className="section_title" data-aos="fade-up">
            About
          </div>

          <div className="section_desc" data-aos="fade-up">
            Join us on a virtual journey filled with tail-wagging joy and
            purrfectly amusing scenarios. "Paws and Whiskers Memeland"
            celebrates the quirks, the cuteness, and the undeniable meme-worthy
            moments that make dogs and cats internet sensations.
          </div>

          <Carousel
            height={250}
            slideSize="10%"
            slideGap="xs"
            controlsOffset="md"
            controlSize={34}
            loop
            mt={44}
          >
            {carouselImages.map((image, index) => (
              <Carousel.Slide key={index}>
                <img src={image} alt="dog and cat" className="carousel_image" />
              </Carousel.Slide>
            ))}
          </Carousel>
        </div>
      </section>

      <div className="section_divider"></div>

      <section className="section tokenomics" id="tokenomics">
        <div className="desktop_max_width">
          <div className="section_title" data-aos="fade-up">
            Token Info
          </div>

          {/* <div className="section_desc" data-aos="fade-up">
            Simple numbers and charts that show the allocation of the $DCAT
            token.
          </div> */}

          {/* <Group justify="center" align="center">
            <Text className="contract_address">
              CA:{" "}
              <a
                href="https://solscan.io/token/DCATEBkugUfHTkJVdGy7xd485snLny8JpVyinpJwuPoV"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                DCATEBkugUfHTkJVdGy7xd485snLny8JpVyinpJwuPoV
              </a>
            </Text>
          </Group> */}

          <Group justify="center">
            <div className="total_supply">
              <div
                className="total_supply_number"
                data-aos="flip-up"
                data-aos-duration="1000"
              >
                1 Billion
              </div>
              <div className="total_supply_title">Total Supply</div>
            </div>

            <RingProgress
              data-aos="fade-up"
              size={370}
              thickness={30}
              sections={sections}
              roundCaps
              label={
                <Stack gap={3} align="center">
                  {sections.map((section) => (
                    <Text
                      key={section.color}
                      fz={16}
                      fw={500}
                      c={section.color}
                    >
                      {section.tooltip}: {section.value}%
                    </Text>
                  ))}
                </Stack>
              }
            />
          </Group>
        </div>
      </section>

      <div className="section_divider"></div>

      <Faqs />
    </div>
  );
};

export default Landing;
