import React, { useState } from "react";
import { ReactComponent as Plus } from "../../assets/svg/faq-plus.svg";
import "./faqs.scss";

const Faqs = () => {
  const [active, setActive] = useState<number>(0);

  const faqs = [
    {
      question:
        "What is DogaCat, and how does it relate to the Solana blockchain?",
      answer:
        "DogaCat is a meme token on the Solana blockchain, specifically designed to celebrate the humorous dynamics between dogs and cats. The project leverages Solana's fast and low-cost transactions to create a seamless and entertaining experience for meme enthusiasts. Explore the world of 'Paws and Whiskers Memeland' on Solana!",
    },
    {
      question:
        "How does DogaCat contribute to the age-old debate of Team Dog versus Team Cat?",
      answer:
        "DogaCat dives into the eternal debate of whether dogs or cats are superior companions. Through cleverly crafted memes, the project captures the essence of this rivalry, creating a virtual journey filled with laughter, joy, and the unique qualities that make both species beloved. It's a lighthearted exploration of the quirks that make dogs and cats internet sensations.",
    },
    {
      question: "What makes DogaCat stand out in the world of meme tokens?",
      answer:
        "DogaCat distinguishes itself by focusing on the universal appeal of dogs and cats, combining adorable moments and hilarious antics in meme form. Unlike other meme tokens, DogaCat is not just about speculative trading; it's a celebration of the timeless joy these furry friends bring to our lives. The Solana blockchain ensures efficient transactions and a vibrant community experience.",
    },
    {
      question:
        "How can I participate in 'Paws and Whiskers Memeland' and contribute to the DogaCat community?",
      answer:
        "To join the DogaCat community, you can acquire DogaCat tokens through the designated channels. Once you're a part of the community, engage in discussions, share your favorite memes, and participate in community events. DogaCat aims to create a lively space for meme enthusiasts who appreciate the humor and charm of dogs and cats.",
    },
    {
      question:
        "Is DogaCat just a meme token, or does it have additional features or utilities?",
      answer:
        "While DogaCat is primarily a meme token, the project may introduce additional features and utilities over time. These could include exclusive NFTs, community contests, or other interactive elements to enhance the overall experience. Stay tuned to DogaCat's official channels for updates on new developments and exciting opportunities within the 'Paws and Whiskers Memeland.'",
    },
  ];

  return (
    <section className="section gradient faqs" id="faqs">
      <div className="faqs-wrapper desktop-max-width">
        <div className="section_title" data-aos="fade-up">
          FAQs
        </div>

        <div className="section_desc" data-aos="fade-up">
          If others are asking, you are most likely thinking the same thing.
          Here are some possible answers.
        </div>

        <div className="faqs-main">
          {faqs.map((item, index) => (
            <div className="faq-item" data-aos="fade-up" key={item.question}>
              <div
                className="f-i-top click no-select"
                onClick={() => setActive(index)}
                onKeyDown={() => setActive(index)}
              >
                <div className="i-question">{item.question}</div>
                <div>
                  <Plus />
                </div>
              </div>
              {active === index && (
                <div
                  className={`f-i-bottom ${
                    active === index ? "is-active" : ""
                  }`}
                  data-aos="fade-up"
                >
                  {item.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faqs;
