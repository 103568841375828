import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import AOS from "aos";
import Landing from "./pages/Landing/Landing";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import "aos/dist/aos.css";
import "./App.scss";
import Airdrop from "./pages/Airdrop/Airdrop";

function App() {
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);

  AOS.init();

  return (
    <div className={showMobileMenu ? "mobile-active" : ""}>
      <Navbar {...{ showMobileMenu, setShowMobileMenu }} />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/airdrop" element={<Airdrop />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
