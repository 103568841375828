import React from "react";
import Logo from "../../assets/png/logo.png";
import { ReactComponent as PVector } from "../../assets/svg/p-vector.svg";
import { FaTelegram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import "./footer.scss";
import { PryButton } from "../Buttons/Buttons";

const Footer = () => {
  return (
    <section className="footer">
      <PVector className="p-vect" />

      <div className="footer-wrapper desktop_max_width">
        <div className="f-top">
          <div className="f-t-left">
            <img height={70} className="logo" src={Logo} alt="Logo" />
          </div>

          <div className="f-center">
            {new Date().getFullYear()} &#169; DOGACAT. All rights reserved.
          </div>

          <div className="f-right">
            <PryButton>Buy</PryButton>

            <div className="click">
              <a
                href="https://twitter.com/dogacat_"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaXTwitter color="#9b68dc" size={24} />
              </a>
            </div>

            <div className="click">
              <a
                href="https://t.me/dogacat_army/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTelegram color="#9b68dc" size={24} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
