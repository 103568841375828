import React from "react";
import "./buttons.scss";

interface Props {
  click: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
}

export const PryButton = ({ children, click, disabled }: Props) => {
  return (
    <button disabled={disabled} onClick={click} className="button primary">
      {children}
    </button>
  );
};

export const SecButton = ({ children, disabled, click }: Props) => {
  return (
    <button onClick={click} disabled={disabled} className="button secondary">
      {children}
    </button>
  );
};

export const TextButton = ({ children, click }: Props) => {
  return (
    <button onClick={click} className="button text">
      {children}
    </button>
  );
};

export const CustomButton = ({ children, click }: Props) => {
  return (
    <button onClick={click} className="button custom">
      {children}
    </button>
  );
};
