import React from "react";
import "./airdrop.scss";

const Airdrop = () => {
  return (
    <div className="airdrop">
      Airdrop
      <br />
      Airdrop
      <br /> Airdrop
      <br /> Airdrop
      <br /> Airdrop
      <br /> Airdrop
      <br /> Airdrop
      <br /> Airdrop
      <br /> Airdrop
      <br /> Airdrop
      <br /> Airdrop
      <br /> Airdrop
      <br /> Airdrop
      <br /> Airdrop
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default Airdrop;
